import './scss/App.scss';
import { Footer } from './components/footer';
import { Header } from './components/header';
import keys1 from './img/keys22.jpg';
import keys2 from './img/avar-otkr-1.jpg';
import keys3 from './img/keys.png';

function App() {

  return (
  <>
    <Header/>
    <div className="main">
    <div className='picture'>
   <img src={keys1} alt=''/>
   <img className='img-disable' src={keys3} alt=''/>
   <img className='img-disable' src={keys2} alt=''/>
   </div>
   <h1 style={{color: 'blue'}}>НАШІ ПОСЛУГИ</h1>
       <ul>
           <li>
               <b>Виготовлення автомобільних ключів. </b>
               <span className='sp-main'> 
               Існує безліч марок і моделей автомобілів, а, відповідно, і ключів до них - від найпростіших зі жалом до 
               смарт-ключів. Наш сервіс працює з усіма видами. Найчастіше така послуга необхідна при втраті ключа або коли 
               приганяється авто з-за кордону, яке може бути взагалі без ключів.
               </span>
           </li>
           <li>
               <b>Виготовлення дублікатів автоключів.</b> 
               <span className='sp-main'>
               Із запитом на цю послугу до нас звертаються частіше, ніж із попередньою, оскільки варіантів, коли може 
               знадобиться зробити копію ключа, досить багато. По-перше, передбачливі автовласники, маючи лише один комплект 
               ключів, роблять другий, що допомагає уникнути ситуацій, коли потрібне аварійне відкриття авто. Також доцільно
                мати запасний ключ на випадок втрати, адже заздалегідь зробити дублікат і дешевшим, і швидше, ніж повністю
                відновити автоключ.
                </span>
           </li>
           <li>
               <b>Ремонт автомобільних ключів та замків.</b> 
               <span className='sp-main'>
               Згодом будь-який механізм може почати давати збій, зноситися, 
               зламатися, у тому числі ключі/замки на машині. У такій ситуації краще не затягувати зі зверненням до фахівця, 
               оскільки проблема якщо є, то вона лише посилюватиметься і чим швидше її вирішить, тим менш витратно це 
               обійдеться для власника. Якщо вас щось бентежить у роботі замку чи ключа, радимо звернутися до наших майстрів, 
               які діагностують та вирішать проблему у найкоротші терміни.
               </span>
           </li>
           <li>
               <b>Заміна корпусу, батарей автоключа. </b> 
               <span className='sp-main'>
               Буває, що корпус ключа може тріснути або подряпатися від падіння, удару (щоб цього уникнути краще мати на 
               корпусі чохол), можуть протертися кнопки, а може просто власник хоче вдосконалити старий ключ і зробити зручніший 
               викидний («викидуху»), тоді можна приїхати в нашу майстерню і підібрати з різних варіантів той, який вам 
               найбільше сподобається і вже через 15 хвилин ваш ключ виглядатиме як новий.
               </span>
           </li>
           <li>
            <b>Ремонт електроніки автоключів.</b> 
            <span className='sp-main'>
               Сучасні автоключі – складні багатофункціональні пристрої, оснащені чіпами, іммобілайзером, мікросхемами, 
               а також останнє покоління – смарт-ключі, які не мають жала та заводять машину кнопкою. Функціонування та влаштування таких ключів 
               досить складне, але це не скасовує того факту, що вони теж можуть виходити з ладу та вимагати професійного ремонту від 
               кваліфікованого фахівця. Крім механічної частини роботи, їх необхідно перепрограмувати та «зв'язувати» з авто на спеціальному 
               обладнанні.
            </span>
           </li>
           <li>
            <b>Розблокування замку запалювання.</b> 
            <span className='sp-main'>
               Цей важливий вузол автомобіля досить вразливий і неправильна, неакуратна його експлуатація, 
               попадання в личинку бруду, природне зношування секретів може призвести до того, що замок заклинить і завести машину буде неможливо. 
               У такій ситуації не варто намагатися самому «полагодити» механізм, оскільки ваші дії можуть призвести до ще більшого пошкодження. 
               Правильним рішенням буде зателефонувати до служби Lock Master та викликати спеціаліста.
            </span>
           </li>
           <li>
            <b>Перекодування автомобільного замку.</b> 
            <span className='sp-main'>
               Ця досить складна і трудомістка процедура зняття замка і зміни його унікального коду може 
               знадобитися, наприклад, коли після втрати ключа власник не хоче наражати своє авто на ризик угону і вирішує поміняти секретність 
               замків і зробити нові ключі.
            </span>
           </li>
       </ul>
       <h2 
       style={{color: 'blue'}}
       >
             В нас можна виготовити АВТОКЛЮЧ любої складності 
       </h2>
       <div className='map'>
       <span>
        <h3>Чекаємо Вас за адресою:</h3>
        <h3>м. Суми, вул. Лучанська, 38</h3> 
        <p style={{fontSize:'1.5em'}}><b>+38(096)081-65-36</b></p>
        </span>

        <iframe 
        title='gmaps'
        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1257.5962217944593!2d34.821693!3d50.920157!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x412903b4ecfa0915%3A0x9dcb97e2c09ffe7d!2z0JDQstGC0L7QutC70Y7Rh9C4!5e0!3m2!1suk!2sus!4v1705778885041!5m2!1suk!2sus" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"'
        >

        </iframe>
       </div>
    </div>
    <Footer/>
  </>
  );
}

export default App;
